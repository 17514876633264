import { GlobalAction } from '#app/utils/global-action';
import { logger } from '#app/utils/logger';
import { parseWithZod } from '@conform-to/zod';
import { z } from 'zod';

export const searchBookSchema = z.object({
  [GlobalAction.SearchBook]: z.string(),
});

/**
 * @description 책 검색
 */
export const searchBook = async (userId: number, formData: FormData) => {
  logger.info('SearchBook called!');
  await parseWithZod(formData, {
    async : true,
    schema: searchBookSchema.superRefine(async (data) => {}),
  },);
  logger.info('SearchBook done!');
};