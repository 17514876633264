import { cn } from '#app/utils/misc';
import { cva } from 'class-variance-authority';
import React from 'react';
import { isMacOs, isWindows } from 'react-device-detect';

import { Icon } from '../icon';
import { Input } from '../input';

export function SearchField({
  command = false,
  disabled = false,
  inputProps,
  onClick,
}: {
	command?:boolean,
  disabled?: boolean,
  inputProps: React.InputHTMLAttributes<HTMLInputElement>,
  onClick?: () => void,
}) {

  const inputRef = React.useRef<HTMLInputElement>(null);

  const cv = cva('', {
    variants: {
      disabled: {
        false: 'border-none',
        true : 'cursor-not-allowed border-gray-20 bg-gray-5 pointer-events-none text-gray-40'
      },
      onClick: {
        true: 'cursor-pointer caret-transparent',
      }
    }
  });

  return (
    <div className='grid grid-flow-row' onClick={() => {
      if (onClick) {
        onClick();
        inputRef.current?.blur();
      }
    }}>
      <div className='min-w-[18rem] min-h-[3.25rem] relative'>
        <Icon className={
          cn(
            'absolute top-1/2 transform -translate-y-1/2 left-5',
            cv({ disabled }),
          )
        } name='regular-search' size='large' />
        <Input
          autoComplete={'off'}
          ref={inputRef}
          {...inputProps}
          className={
            cn(
              'pl-[3.25rem] py-0',
              'text-label-1-medium xs:font-sfProMedium sm:font-pretendardMedium',
              'bg-gray-10',
              'rounded-[6.25rem]',
              'hover:bg-gray-20',
              inputProps.className,
              cv({ disabled, onClick: !!onClick }),
            )
          }
        />
        {
          command &&
          (
            <div className={
              cn('hidden lg:block')
            }>
              {
                isMacOs && <Icon className={
                  cn(
                    'absolute top-1/2 transform -translate-y-1/2 right-7 text-gray-40',
                    cv({ disabled }),
                  )
                } name='command' size='small' />
              }
              {
                isWindows && <span className={
                  cn(
                    'absolute top-1/2 transform -translate-y-1/2 right-7 text-gray-40 text-caption-2-regular',
                    cv({ disabled }),
                  )
                }>
                  Ctrl
                </span>
              }
              <span className={
                cn(
                  'absolute top-1/2 transform -translate-y-1/2 right-5 text-gray-40 text-caption-2-regular',
                  cv({ disabled }),
                )
              }>
            K
              </span>
            </div>
          )
        }
      </div>
    </div>
  );
}