/* eslint-disable complexity */
/* eslint-disable max-lines */
import type { loader } from '#app/root';
import type { action as ActionBookAction} from '#app/routes/action-book';
import type {SearchBookInterface, loader as SearchBookLoader} from '#app/routes/search-book';
import type {loader as SearchKeywordLoader} from '#app/routes/search-keyword';
import type { keywordZodSchema } from '#prisma/zod/keyword';
import type { z } from 'zod';

import { searchBookSchema } from '#app/action/search-book';
import { searchKeywordSchema } from '#app/action/search-keyword';
import { GlobalAction } from '#app/utils/global-action';
import { cn, useDebounce } from '#app/utils/misc';
import { track } from '#app/utils/track';
import { getInputProps, useForm } from '@conform-to/react';
import { getZodConstraint } from '@conform-to/zod';
import { redirect } from '@remix-run/node';
import { useFetcher, useRouteLoaderData } from '@remix-run/react';
import React, { useState } from 'react';
import { match } from 'ts-pattern';

import { Button } from '../ui/button/button';
import { Checkbox } from '../ui/checkbox';
import Chip from '../ui/chip/chip';
import { FloatingButton } from '../ui/floating-button/floating-button';
import { Icon } from '../ui/icon';
import { Modal, ModalContent, ModalFooter, ModalMain, ModalTrigger } from '../ui/modal/modal';
import { SearchBook } from '../ui/search-book/search-book';
import { SearchField } from '../ui/search-field/search-field';
import { ConfirmDialog } from './confirm-dialog';
import { UpdateBookContent } from './upload-book-dialog/update-book-content-dialog';
import { UploadBookContent } from './upload-book-dialog/upload-book-content-dialog';

enum Step {
  SearchBook = 'searchBook',
  SelectKeywords = 'selectKeywords',
  UpdateBook = 'updateBook',
  UpdateKeywords = 'updateKeywords',
  UploadBook = 'uploadBook',
}
type Keyword = z.infer<typeof keywordZodSchema>;
type UploadBookType = 'button' | 'comment' | 'edit' | 'sidebar';

interface UplaodBookDialogProps {
  book?: SearchBookInterface;
  buttonSize?: 'small';

  selectedKeywords?: Set<Keyword>;
  selectedLibraryId?: number;
  showPrefixIcon?: boolean;
  type: UploadBookType;
}
export const UploadBookDialog: React.FC<UplaodBookDialogProps> = (props) => {
  const root = useRouteLoaderData<typeof loader>('root');
  const comment = React.useRef<null | string>(null);

  if (!root?.user) {
    throw redirect('/open-library?signup=');
  }
  
  const propStep = match(props.type)
    .with('edit', () => Step.UpdateBook)
    .with('comment', () => Step.SelectKeywords)
    .otherwise(() => Step.SearchBook);

  const [step, setStep] = useState<Step>(propStep);
  const [selectedBook, setSelectedBook] = useState<SearchBookInterface | null>(props.book || null);
  const [selectedKeywords, setSelectedKeywords] = useState<Set<Keyword>>(props.selectedKeywords || new Set());
  const [searchKeyword, setSearchKeyword] = useState<string | undefined>(undefined);
  const [open, setOpen] = useState(false);


  const onClose = () => {
    setOpen(false);
    setStep(propStep);
    setSelectedBook(props.book || null);
    setSelectedKeywords(props.selectedKeywords || new Set());
    comment.current = null;
  };

  React.useEffect(() => {
    return () => {
      setOpen(false);
      setStep(propStep);
      setSelectedBook(null);
      setSelectedKeywords(new Set());
      comment.current = null;
    };
  }, [propStep]);


  return (
    <Modal onOpenChange={setOpen} open={open}>
      <ModalTrigger asChild>
        {
          // TODO: Trigger를 Children으로 주입하는 형태로 변경해야할 것 같음. 너무 prop이 많아
          match(props.type)
            .with(
              'sidebar', 
              () => 
                <Button
                  data-umami-event="click_upload_book_sidebar"
                  onClick={() => setOpen(!open)}
                  rounded='md' size={props.buttonSize ?? 'medium'} type='button' variant='black'>
                  <Icon name='fill-add' size={props.buttonSize ?? 'medium'} />
                    책 업로드
                </Button>
            )
            .with(
              'button', 
              () => 
                <Button
                  data-umami-event="click_upload_book_button"
                  onClick={() => setOpen(!open)}
                  rounded={'lg'}
                  size={props.buttonSize ?? 'medium'}
                  type='button'
                  variant={'primary'}
                >
                  {props.showPrefixIcon && <Icon name='fill-add' size={props.buttonSize ?? 'medium'} />}
                  책 업로드
                </Button>
            )
            .with(
              'edit',
              () => 
                <Button
                  children={'수정'}
                  data-umami-event="click_update_comment_button"
                  onClick={() => {
                    if (selectedBook) {
                      void setStep(Step.UpdateBook);
                    }
                  }}
                  rounded={'md'}
                  size={props.buttonSize ?? 'small'}
                  type='button'
                  variant={'black'}
                />
            )
            .with(
              'comment',
              () => 
                <FloatingButton
                  data-umami-event="click_create_comment_button"
                  onClick={() => setOpen(!open)}
                  size={props.buttonSize ?? 'small'}
                  type='button' variant={'black'}
                >
                  코멘트 남기기
                </FloatingButton>
            )
            .otherwise(() => null)
        }
      </ModalTrigger>
      {
        open && <>
          {step === Step.SearchBook && <SearchBookContent 
            onBookClick={(book, searchKeyword) => { 
              if (book.isUploaded) {
                void setStep(Step.UpdateBook);
              }
              else {
                void setStep(Step.SelectKeywords);
              }
              void setSearchKeyword(searchKeyword);
              void setSelectedKeywords(new Set(book.keywords));
              void setSelectedBook(book);
            }}
            onClose={onClose}
            searchKeyword={searchKeyword}
          />}
          {step === Step.SelectKeywords && <SelectKeywordContent 
            careerKeyword={root?.user.careerKeyword as unknown as Keyword}
            groupedKeyword={root?.groupedKeyword as unknown as Record<string, Keyword[]>}
            onClose={onClose}
            onNext={(keywords) => {
              setSelectedKeywords(keywords);
              setStep(Step.UploadBook);
            }}
            onPrve={() => setStep(Step.SearchBook)}
            selectedBook={selectedBook}
            selectedKeywords={selectedKeywords.size > 0 ? selectedKeywords : undefined}
          />}
          {step === Step.UploadBook && <UploadBookContent
            book={selectedBook!}
            comment={comment.current}
            onClose={onClose}
            onPrev={(value) => { 
              comment.current = value;
              setStep(Step.SelectKeywords);
            }}
            selectedKeywords={selectedKeywords}
            user={root.user} />}
          {step === Step.UpdateBook && selectedBook && <UpdateBookContent
            book={selectedBook}
            onClose={onClose}
            onNext={() => setStep(Step.UpdateKeywords)}
            onPrev={onClose}
            selectedKeywords={selectedKeywords}
            selectedLibraryId={selectedBook?.libraryId ?? props.selectedLibraryId!}
            user={root.user}
          />}
          {step === Step.UpdateKeywords && <SelectKeywordContent 
            careerKeyword={root?.user.careerKeyword as unknown as Keyword}
            groupedKeyword={root?.groupedKeyword as unknown as Record<string, Keyword[]>}
            onClose={onClose}
            onNext={(keywords) => {
              setSelectedKeywords(keywords);
              setStep(Step.UpdateBook);
            }}
            onPrve={() => setStep(Step.UpdateBook)}
            selectedBook={selectedBook}
            selectedKeywords={selectedKeywords.size > 0 ? selectedKeywords : undefined}
          />}
        </>
      }
    </Modal>
  );
};



interface SearchBookContentProps {
  onBookClick: (searchBook: SearchBookInterface, searchKeyword: string) => void;
  onClose: () => void;
  searchKeyword?: string;
 }
const SearchBookContent: React.FC<SearchBookContentProps> = (props) => {
  const books = useFetcher<typeof SearchBookLoader>();
  const ref = React.useRef<HTMLFormElement>(null);
  const [open, setOpen] = useState<{data: SearchBookInterface | null, value: boolean}>({data: null, value: false});
  const [form, fields] = useForm({
    constraint  : getZodConstraint(searchBookSchema),
    defaultValue: {
      [GlobalAction.SearchBook]: props.searchKeyword,
    },
    id: 'search-book-form',
  });
  const handleFormChange = useDebounce((form: HTMLFormElement) => {
    void books.submit(form, {
      action: '/search-book',
      method: 'GET',
    });
  }, 300);

  React.useEffect(() => {
    if (form.initialValue && ref) {
      books.submit(ref.current, {
        action: '/search-book',
        method: 'GET',
      });
    }
  }, [ref]);

  React.useEffect(() => {
    void track('view_search_book_modal');
  }, []);


  return (
    <ModalContent className='w-[50rem] h-[42.5rem] grid grid-rows-[auto_1fr] rounded-[1.75rem] gap-3'
      onInteractOutside={(e) => {
        void props.onClose();
      }}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          void props.onClose();
        }
      }} showClose={false} size='large'>
      <section className='mx-5 my-4 grid grid-flow-col grid-cols-[1fr_auto] gap-2.5'>
        <books.Form id={form.id} onChange={e => handleFormChange(e.currentTarget)} onKeyDown={
          (e) => {
            if(e.key === 'Enter') {
              e.preventDefault();
            }
          }
        } 
        ref={ref}>
          <SearchField
            inputProps={{
              placeholder: '업로드할 책 제목 혹은 저자명 검색',
              ...getInputProps(fields[GlobalAction.SearchBook], {type: 'text'})
            }}
          />
        </books.Form>
        <div className='grid content-center'>
          <Button
            children={'취소'}
            data-umami-event='click_search_book_close'
            onClick={props.onClose}
            rounded={'md'}
            size='medium'
            type='button'
          />
        </div>
      </section>
      <ModalMain className='mx-5 overflow-auto'>
        <section className="grid grid-flow-row gap-5">
          {
            books.state === 'loading' && form.value !== undefined && (
              Array.from({length: 5}).map((_, index) => <LoadingSearchBook key={index}  />)
            )
          }
          {books.state === 'idle' && form.value !== undefined && books.data?.books.length === 0 && (
            <div className='grid grid-flow-row gap-3 justify-center text-center'>
              <span className='text-subtitle-1-bold-desktop xs:font-sfProBold sm:font-pretendardBold text-gray-70'>찾는 결과가 없습니다.</span>
              <span className='text-label-2-regular xs:font-sfProLight sm:font-pretendardLight text-gray-60'>책 제목과 저자명으로 책을 찾을 수 있습니다.</span>
            </div>
          )}
          {books.state === 'idle' && books.data?.books.map((book, index) => {
            return <SearchBook
              author={book.author ?? ''}
              className='p-2'
              img={book.image ?? ''}
              isUploaded={book.isUploaded}
              key={index}
              onClick={() => {
                if(book.isUploaded) {
                  setOpen({data: book as unknown as SearchBookInterface, value: true});
                  return;
                } else {
                  props.onBookClick(book as unknown as SearchBookInterface, fields[GlobalAction.SearchBook].value as string);
                }
              }}
              readCount={book.readCount}
              reviewCount={book.reviewCount} 
              title={book.title}
            />;
          })}
        </section>
      </ModalMain>
      {open.value && <ConfirmDialog
        cancelButtonTitle='취소하기'
        onCancel={() => {
          setOpen(state => ({...state, value: false}));
        }}
        onSubmit={() => {
          props.onBookClick(open.data!, fields[GlobalAction.SearchBook].value as string);
        }}
        open={open.value}
        setOpen={(value) => setOpen(state => ({...state, value,}))}
        subTitle='코멘트를 수정할까요?'
        submitButtonTitle={'수정하기'}
        title='이미 업로드한 책입니다.'
      />}
    </ModalContent>
  );
};

export const LoadingChip: React.FC = () => {
  return (
    <article className='h-[2.5rem] w-[4rem] rounded-full bg-gray-20'>
    </article>
  );
};

export const LoadingSearchBook: React.FC = () => {
  return (
    <article className='h-[6.375rem] rounded-xl grid grid-flow-col gap-5 justify-start items-center p-2'>
      <div className={cn('w-[4.5rem] h-[6.375rem] rounded-xl bg-gray-20')} />
      <div className='grid grid-flow-row gap-2'>
        <h1 className='text-label-1-bold xs:font-sfProBold sm:font-pretendardBold text-gray-90 w-[15rem] h-[1.5rem] bg-gray-20 rounded-md'></h1>
        <h2 className="text-label-2-regular xs:font-sfProLight sm:font-pretendardLight text-gray-60 w-[4rem] h-4 bg-gray-20 rounded-md"></h2>
      </div>
    </article>
  );
};

interface SelectKeywordContentProp {
  readonly careerKeyword: Keyword;
  readonly groupedKeyword: Record<string, Keyword[]>;
  readonly onClose: () => void;
  readonly onNext: (keywords: Set<Keyword>) => void;
  readonly onPrve: () => void;
  readonly selectedBook: SearchBookInterface | null;
  readonly selectedKeywords?: Set<Keyword>;
}
const SelectKeywordContent: React.FC<SelectKeywordContentProp> = (props) => {
  const keywords = useFetcher<typeof SearchKeywordLoader>();
  const [wantRead, setWantRead] = useState<boolean>(false);
  const wantReadFetcher = useFetcher<typeof ActionBookAction>();
  const [form, fields] = useForm({
    constraint: getZodConstraint(searchKeywordSchema),
    id        : 'search-keyword-form',
  });
  const [open, setOpen] = React.useState<{type: 'back' | 'exit'; value: boolean}>({type: 'exit', value: false});
  const handleFormChange = useDebounce((value: HTMLFormElement) => {
    void keywords.submit(value, {
      action: '/search-keyword',
      method: 'GET',
    });
  }, 200);
  
  const [selectedKeywords, setSelectedKeywords] = React.useState<Set<Keyword>>(new Set(
    props.selectedKeywords ? props.selectedKeywords : props.careerKeyword.isOpen === true ? [props.careerKeyword] : []
  ));
  const orderedGroupedKeyword = Object.entries(keywords.data?.groupedKeyword ?? props.groupedKeyword).sort((a, b) => {
    if(a[0] === '기타') {
      return 1;
    }
    if(b[0] === '기타') {
      return -1;
    }
    return 0;
  }).filter(([groupName]) => groupName !== '커리어');
  const ref = React.useRef<Keyword[]>([...selectedKeywords]);

  const dirty = (): boolean => {
    const compare = (a: Keyword, b: Keyword) => {
      if(a.id < b.id) {
        return -1;
      }
      if(a.id > b.id) {
        return 1;
      }
      return 0;
    };
    const b = [...selectedKeywords].sort(compare).map(k => k.id);
    if (b.length===0) {
      return false;
    }
    if(ref.current !== undefined) {
      const r = ref.current?.sort(compare).map(k => k.id);
      return btoa(r.join('')) !== btoa(b.join('')) || selectedKeywords.size === 0;
    } 
    return false;
  };

  React.useEffect(() => {
    void track('view_search_keyword_modal');
  }, []);

  const onSubmit = useDebounce((event: React.FormEvent<HTMLFormElement>) => {
    event.currentTarget.append('bookActionType', 'wantRead');
    event.currentTarget.append('searchBook', JSON.stringify(props.selectedBook!));
    wantReadFetcher.submit(event.currentTarget, { action: '/action-book', method: 'POST' });
  }, 200);

  React.useEffect(() => {
    if(wantReadFetcher.data) {
      props.onClose();

    }
  }, [wantReadFetcher.data, props.onClose]);


  return (
    <ModalContent className='w-[50rem] h-[42.5rem] grid grid-rows-[auto_1fr] rounded-[1.75rem]'
      onInteractOutside={(e) => {
        if (dirty()) {
          void setOpen({type: 'exit', value: true});
          e.preventDefault();
          return;
        }
        e.preventDefault();
      }}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          if (dirty()) {
            void setOpen({type: 'exit', value: true});
            return;
          }
          props.onClose();
        }
      }}
      showClose={false}
      size='large'
    >
      <section className='mx-5 my-4 grid grid-flow-col grid-cols-[1fr_auto] gap-3'>
        {
          wantRead === false
            ? <keywords.Form id={form.id} onChange={e => handleFormChange(e.currentTarget)}>
              <SearchField
                inputProps={{
                  autoFocus  : true,
                  placeholder: '이 책의 키워드 검색',
                  ...getInputProps(fields[GlobalAction.SearchKeyword], {type: 'text'})
                }}
              />
            </keywords.Form>
            : <SearchField
              disabled
              inputProps={{
                value: '읽고 싶은 책으로 저장하기',
              }}
            />
        }
        <div className='grid content-center grid-flow-col gap-3'>
          <div className='border border-gray-20 px-3 py-2.5 rounded-lg flex justify-center items-center gap-2'>
            <Checkbox id="wantRead" onCheckedChange={(v) => {
              setWantRead(v === true ? true : false);
            }} />
            <label className='text-label-2-medium font-pretendardMedium text-gray-90' htmlFor="wantRead">
              읽고 싶은 책
            </label>
          </div>
          <Button
            children={'이전'}
            data-umami-event='click_search_keyword_prev'
            onClick={() => {
              if (dirty()) {
                void setOpen({type: 'back', value: true});
                return;
              }
              props.onPrve();
            }}
            rounded={'md'}
            size='medium'
            type='button'
          />
          {
            wantRead === false
              ? <Button
                children={'다음'}
                data-umami-event='click_search_keyword_next'
                disabled={selectedKeywords.size === 0}
                onClick={() => {
                  props.onNext(selectedKeywords);
                }}
                rounded={'md'}
                size='medium'
                type='button'
                variant={'primary'}
              />
              : 
              <wantReadFetcher.Form action='/action-book' method='POST' onSubmit={onSubmit}>
                <Button
                  children={'완료'}
                  data-umami-event='click_want_read_done'
                  disabled={false}
                  loading={wantReadFetcher.state === 'loading' ? 'true' : 'false'}
                  rounded={'md'}
                  size='medium'
                  type='submit'
                  variant={'primary'}
                />
                <input name="searchBook" type="hidden" value={JSON.stringify(props.selectedBook!)} />
                <input name="bookActionType" type="hidden" value={'wantRead'} />
              </wantReadFetcher.Form>
          }
        </div>
      </section>
      {
        wantRead === false
          ? <>
            <ModalMain className='mx-5 overflow-auto grid gap-7 content-baseline mt-3'>
              {keywords.state === 'idle' && form.value !== undefined && orderedGroupedKeyword.length === 0 && (
                <div className='grid grid-flow-row gap-3 justify-center text-center'>
                  <span className='text-subtitle-1-bold-desktop xs:font-sfProBold sm:font-pretendardBold text-gray-70'>찾는 결과가 없습니다.</span>
                  <span className='text-label-2-regular xs:font-sfProLight sm:font-pretendardLight text-gray-60'>다른 키워드로 검색해주세요.</span>
                </div>
              )}
              {
                keywords.state === 'loading' &&  (
                  Array.from({length: 5}).map((_, index) => <LoadingGroupdKeyword key={index}  />)
                )
              }
              {
                keywords.state === 'idle' && orderedGroupedKeyword
                  .map(([groupName, keywords], i) => 
                  { 
                    return <GroupedKeyword
                      careerKeyword={props.careerKeyword}
                      groupName={groupName} key={i} keywords={keywords  as Keyword[]} onClick={(keyword) => {
                        const set = new Set<Keyword>(selectedKeywords);
                        if (props.careerKeyword.id === keyword.id) {
                          return;
                        }
                        if([...set].some(k => k?.id === keyword?.id)) {
                          void set.delete(keyword);
                          set.forEach(k => {
                            if(k.id === keyword?.id) {
                              void set.delete(k);
                            }
                          });
                          void setSelectedKeywords(set);
                          return;
                        }
                        if(set.size === 4) {
                          return;
                        }
                        void set.add(keyword);
                        void setSelectedKeywords(set);
                      }} 
                      selectedKeywords={selectedKeywords} 
                    />; })
              }
            </ModalMain>
            <ModalFooter className='grid grid-flow-row sm:justify-start gap-2.5 px-5 py-5'>
              <div className='grid grid-flow-col gap-3 justify-start'>
                <span className='grid grid-flow-col gap-1 items-center'>
                  <b className='text-label2-bold xs:font-sfProBold sm:font-pretendardBold text-gray-100'>선택 키워드</b>
                  <b className='text-body-2-regular xs:font-sfProLight sm:font-pretendardLight text-gray-70'>{selectedKeywords.size}/4</b>
                </span>
                {props.careerKeyword.isOpen === true && <span className='grid text-gray-50 text-caption-1-regular xs:font-sfProLight sm:font-pretendardLight items-center'>내 커리어 키워드는 필수로 등록됩니다.</span>}
              </div>
              <div className='flex gap-2 justify-start overflow-x-auto min-h-11'>
                {[...selectedKeywords].map(sk => {
                  return (
                    <Chip
                      disabled={sk.id === props.careerKeyword.id}
                      key={sk.id}
                      name={'upload-book-chip-key'}
                      onClick={() => {
                        const set = new Set<Keyword>(selectedKeywords);
                        if([...set].some(k => k?.id === sk?.id)) {
                          void set.delete(sk);
                          set.forEach(k => {
                            if(k.id === sk?.id) {
                              void set.delete(k);
                            }
                          });
                          void setSelectedKeywords(set);
                          return;
                        }
                      }} 
                      postFixNode={
                        sk.id !== props.careerKeyword.id
                          ? <Icon name='regular-close' />
                          : null
                      }
                      size='small'
                      value={sk.name}
                      variant={'black'}
                    >
                      {sk.name}
                    </Chip>
                  );
                })}
              </div>
            </ModalFooter>
          </>
          : <ModalMain className='mx-10 my-5 overflow-auto grid gap-7 content-baseline'>
            <div className='flex-col flex justify-center items-center gap-3 py-10'>
              <h1 className='text-subtitle-1-bold-desktop xs:font-sfProBold sm:font-pretendardBold text-gray-70'>
                읽고싶은 책에 저장합니다.
              </h1>
              <h2 className='text-label-2-regular xs:font-sfProLight sm:font-pretendardLight text-gray-60'>
                읽고 싶은 책에는 키워드나 코멘트를 남길 수 없습니다.
              </h2>
            </div>
          </ModalMain>
      }
      {open.value && <ConfirmDialog
        onCancel={() => {
          void setOpen({type: open.type, value: false});
        }}
        onSubmit={() => {
          void setOpen({type: open.type, value: false});
          if (open.type === 'exit') {
            props.onClose();
            return;
          } else {
            props.onPrve();
          }
        }}
        open={open.value}
        setOpen={(v) => setOpen({type: open.type, value: v})}
        submitButtonTitle={open.type === 'exit' ? '나가기' : '뒤로가기'}
        title='작성중인 내용이 모두 사라집니다.'
      />}
    </ModalContent>
  );
};

const LoadingGroupdKeyword = () => {
  return (
    <article className='grid grid-flow-row gap-3'>
      <div className='grid grid-flow-col gap-1 justify-start text-label-2-bold xs:font-sfProBold sm:font-pretendardBold'>
        <span className='w-14 h-7 bg-gray-20 rounded-md'></span>
      </div>
      <div className='flex flex-wrap gap-2 content-baseline'>
        {
          Array.from({length: Math.floor(Math.random() * 10)+3}).map((_, index) => (
            <div className='rounded-md w-24 h-8 bg-gray-20' key={index} />
          ))
        }
      </div>
    </article>
  );
};


type GroupedKeywordProps = {
  readonly careerKeyword: Keyword;
  readonly groupName: string;
  readonly keywords: Keyword[];
  readonly onClick: (keyword: Keyword) => void;
  readonly selectedKeywords: Set<Keyword>;
};
const GroupedKeyword: React.FC<GroupedKeywordProps> = ({careerKeyword, groupName, keywords, onClick, selectedKeywords}) => {
  return (
    <article className='grid grid-flow-row gap-3'>
      <div>
        <span className='grid grid-flow-col gap-1 justify-start text-label-2-bold xs:font-sfProBold sm:font-pretendardBold'>
          <Icon name='regular-hashtag' size='medium' />
          {groupName}
        </span>
      </div>
      <div className='flex flex-wrap gap-2 content-baseline'>
        {
          keywords.map((keyword, i) => { 
            return <Chip
              disabled={keyword.id === careerKeyword.id}
              key={i}
              name={'chip-key'}
              onClick={(e) => onClick(keyword)}
              size='small' 
              value={keyword.name}
              variant={[...selectedKeywords].some(k => k?.id === keyword?.id) === false ? 'gray' : 'black'}
            >
              {keyword.name}
            </Chip>;
          })
        }
      </div>
    </article>
  );
};