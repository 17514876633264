import { cn } from '#app/utils/misc';

import ProgressiveImg from '../progressive-image';

interface Props {
  author: string;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  hoverOff?: boolean;
  img: string;

  /**
   * 업로드 여부
   */
  isUploaded?: boolean;
  /**
   * 
   */
  onClick: () => void
  /**
   * 읽은 사람
   */
  readCount?: number;
  /**
   * 코멘트 수
   */
  reviewCount?: number;
  

  title: string;
}

export const SearchBook: React.FC<Props> = (props) => {
  const { author, children, className, disabled, hoverOff, img, isUploaded, onClick, readCount, reviewCount, title } = props;
  const renderStats = () => {
    const stats: React.ReactNode[] = [];
    if (readCount !== undefined) { stats.push(<>읽은 사람 {readCount.toLocaleString()}명</>); }
    if (reviewCount !== undefined) { stats.push(<>코멘트 {reviewCount.toLocaleString()}개</>); }
    if (isUploaded) { stats.push(<span className='text-point-blue-70 font-pretendardMedium text-label-2-medium'>내가 읽은 책</span>); }
    
    return stats.map((stat, index) => (
      <>
        {index > 0 && <span>·</span>}
        {stat}
      </>
    ));
  };

  return (
    <article
      className={
        cn(
          'rounded-xl grid grid-flow-col gap-6 justify-start items-center',
          'gap-5',
          {
            'active:bg-gray-30'   : !hoverOff,
            'cursor-not-allowed'  : disabled,
            'hover:bg-gray-20'    : !hoverOff,
            'hover:cursor-pointer': !hoverOff,
            'pointer-events-none' : disabled
          },
          className,
        )
      }
      onClick={onClick}
    >
      <div className={
        cn(
          'grid rounded-xl max-w-[4.5rem] justify-center', 
        )
      }>
        <ProgressiveImg
          className='rounded-md shadow-lg max-h-[102px]'
          placeholderSrc={img+'?type=f50'}
          src={img}
        />
      </div>
      <div className='grid gap-3'>
        <div className='grid grid-flow-row gap-1'>
          <h1 className={
            cn(
              'text-gray-90 text-label-1-medium font-pretendardMedium',
            )
          }>{title}</h1>
          <div className={cn(
            'grid grid-flow-col gap-1 justify-start',
            'text-gray-60',
            'text-label-2-regular xs:font-sfProLight sm:font-pretendardLight',
          )}>
            <h2>{author}</h2>
            {renderStats().length !== 0 && <span>·</span>}
            {renderStats()}
          </div>
        </div>
        <div>
          {children}
        </div>
      </div>
    </article>
  );
};