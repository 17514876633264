import type { Book, Review } from '@prisma/client';

import { prisma } from '#app/utils/db.server';
import { GlobalAction } from '#app/utils/global-action';
import { logger } from '#app/utils/logger';
import { NaverBookItem } from '#app/utils/naver.server';
import { parseWithZod } from '@conform-to/zod';
import { z } from 'zod';

export const createCommentSchema = z.object({
  [GlobalAction.CreateComment]: z.string().optional(),
  book                        : z.string(),
  selectedKeywords            : z.string(),
  selectedLibrary             : z.string(),
});

/**
 * @description 코멘트 생성
 */
export const createComment = async (userId: number, formData: FormData) => {
  logger.info('CreateComment called!');
  let review: Review;
  let book: Book;
  
  await parseWithZod(formData, {
    async : true,
    schema: createCommentSchema.superRefine(async (data) => {
      const parsed = NaverBookItem.safeParse(JSON.parse(data['book']!));
      
      const ids = data.selectedKeywords.split(',') as string[];
      const keywords = await prisma.keyword.findMany({
        where: {
          id: {
            in: ids.map(r => +r),
          },
        },
      });

      if (parsed.success === false) {
        logger.error(parsed.error);
        throw new Error('Invalid book');
      }

      const bookEntity = await prisma.book.findFirst({
        where: {
          isbn: parsed.data.isbn,
        },
      });

      const bookItem = {
        author     : parsed.data.author,
        description: parsed.data.description,
        image      : parsed.data.image,
        isbn       : parsed.data.isbn,
        link       : parsed.data.link,
        publishedAt: parsed.data.pubdate,
        publisher  : parsed.data.publisher,
        title      : parsed.data.title,
      };

      if (bookEntity) {
        book = await prisma.book.update({
          data : bookItem,
          where: {
            id: bookEntity.id,
          }
        });
      } else {
        book = await prisma.book.create({
          data: bookItem,
        });
      }

      const reviewEntity = await prisma.review.findFirst({
        where: {
          bookId   : book.id,
          deletedAt: {
            equals: null,
          },
          ownerId: userId
        }
      });

      const reviewItem = {
        bookId : book.id,
        content: data[GlobalAction.CreateComment] ?? '',
        ownerId: userId,
      };

      // TODO: 업데이트니까 따로 액션을 뺴서 진행하는게 좋음.
      if (reviewEntity) {
        const response = await prisma.$transaction(async tx => {
          const response = await tx.review.update({
            data : reviewItem,
            where: {
              id: reviewEntity.id,
            },
          });
          await tx.reviewToKeyword.deleteMany({
            where: {
              reviewId: reviewEntity.id,
            },
          });
          await tx.reviewToKeyword.createMany({
            data: keywords.map(keyword => ({
              keywordId: keyword.id,
              reviewId : reviewEntity.id,
            })),
          });
          return response;
        });
        review = {...response,};
      } else {
        const alreadyReviews = await prisma.review.findMany({
          select: { id: true, },
          where : {bookId: book.id}
        });
        await prisma.$transaction(async tx => {
          await tx.review.updateMany({
            data : { show: false,},
            where: { id: { in: alreadyReviews.map(alreadyReview => alreadyReview.id) },},
          });
          const response = await tx.review.create({
            data: {
              ...reviewItem,
              show: true,
            },
          });
          await tx.reviewToKeyword.createMany({
            data: keywords.map(keyword => ({
              keywordId: keyword.id,
              reviewId : response.id,
            })),
          });
          const reviewIds = (await tx.reviewToLibrary.findMany({
            select: {
              reviewId: true,
            },
            where: {
              libraryId: +data['selectedLibrary']!,
            }
          })).map(rtl => rtl.reviewId);

          const rtks = (await tx.reviewToKeyword.groupBy({
            _count: {
              keywordId: true,
            },
            by     : 'keywordId',
            orderBy: {
              _count: {
                keywordId: 'desc',
              }
            },
            where: {
              reviewId: {
                in: reviewIds
              }
            }
          }));

          await tx.library.update({
            data: {
              relatedKeywordIds: rtks.slice(0, 4).map(rtks => rtks.keywordId),
            },
            where: {
              id: +data['selectedLibrary']!,
            }
          });
          const defaultLibrary = await tx.userToLibrary.findFirst({
            orderBy: {
              createdAt: 'asc',
            },
            where: {
              userId,
            },
          });
          if (defaultLibrary && defaultLibrary.libraryId !== +data['selectedLibrary']!) {
            await tx.reviewToLibrary.create({
              data: {
                libraryId: defaultLibrary.libraryId,
                reviewId : response.id,
              }
            });
          }
          await tx.reviewToLibrary.create({
            data: {
              libraryId: +data['selectedLibrary']!,
              reviewId : response.id,
            }
          });
          review = { ...response };
        });
      }
    }),
  });
  logger.info('CreateComment done!');
  return {
    book  : book!,
    review: review!
  };
};