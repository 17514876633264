import type { loader as rootLoader } from '#app/root';
import type { rootAction } from '#app/root-action';
import type { loader as getLibrariesLoader } from '#app/routes/_library+/get-libraries.$userId';
import type { SearchBookInterface } from '#app/routes/search-book';
import type { Keyword } from '@prisma/client';
import type { SerializeFrom } from '@remix-run/node';

import { createCommentSchema } from '#app/action/create-comment';
import { Field, Textarea } from '#app/components/forms';
import { Button } from '#app/components/ui/button/button';
import { ModalContent, ModalMain } from '#app/components/ui/modal/modal';
import { SearchBook } from '#app/components/ui/search-book/search-book';
import UserLibraryInput from '#app/components/user-library-input';
import { GlobalAction } from '#app/utils/global-action';
import { track } from '#app/utils/track';
import { getInputProps, getTextareaProps, useForm } from '@conform-to/react';
import { getZodConstraint } from '@conform-to/zod';
import { useFetcher } from '@remix-run/react';
import React, { useEffect, useState } from 'react';
import { P, match } from 'ts-pattern';

interface UploadBookContentProps {
  readonly book: SearchBookInterface;
  readonly comment?: null | string;
  readonly onClose: () => void;
  readonly onPrev: (v: string) => void;
  readonly selectedKeywords: Set<Keyword>;
  readonly user: SerializeFrom<typeof rootLoader>['user']
 }
export const UploadBookContent: React.FC<UploadBookContentProps> = (props) => {
  const fetcher = useFetcher<typeof rootAction>();
  const getLibrariesFetcher = useFetcher<typeof getLibrariesLoader>();

  const [form, fields] = useForm({
    constraint: getZodConstraint(createCommentSchema),
    id        : GlobalAction.CreateComment,
  });
  const [value, setValue] = useState(props.comment ?? '');
  const [selectedLibrary, setSelectedLibrary] = useState<string | undefined>();

  const onSubmit = (form: HTMLFormElement) => {
    void fetcher.submit(form, {
      action: '/',
      method: 'POST',
    });
  };

  useEffect(() => {
    if (fetcher.data?.status === 'success') {
      props.onClose();
    }
  }, [fetcher.data, props]);

  useEffect(() => {
    void track('view_create_comment_modal');
    getLibrariesFetcher.load(`/get-libraries/${props.user!.id}`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <ModalContent className='w-[50rem] h-[42.5rem] grid grid-rows-[auto_1fr] rounded-[1.75rem]' showClose={false} size='large'>
      <fetcher.Form action='/' id={form.id} method='POST' onSubmit={e => onSubmit(e.currentTarget)}>
        <div className='h-0 hidden'>
          <Field
            inputProps={
              {
                ...getInputProps(fields.book, {type: 'text'}),
                value: JSON.stringify(props.book)
              }
            }
          />
          <Field
            inputProps={{
              ...getInputProps(fields.selectedKeywords, {type: 'text'}),
              value: [...props.selectedKeywords].map(k => k.id).join(),
            }}
          />
        </div>
        <section className='mx-5 my-6 pl-2 grid grid-flow-col grid-cols-[1fr_auto] gap-2.5 items-center'>
          <h1 className='text-title-2-bold-desktop xs:font-sfProBold sm:font-pretendardBold text-gray-90'>코멘트 남기기</h1>
          <div className='grid content-center grid-flow-col gap-2'>
            <Button
              children={'이전'}
              data-umami-event='click_create_comment_prev'
              onClick={() => {
                props.onPrev(value);
              }}
              rounded={'md'}
              size='medium'
              type='button'
            />
            <Button
              children={'업로드'}
              data-umami-event='click_create_comment'
              loading={(fetcher.state === 'loading' || fetcher.state === 'submitting') ? 'true' : 'false'}
              rounded={'md'}
              size='medium'
              variant={'primary'}
            />
          </div>
        </section>
        <ModalMain className='mx-5 my-5 pl-2 overflow-auto grid grid-flow-row gap-5 content-baseline'>
          <SearchBook
            author={props.book.author}
            hoverOff
            img={props.book.image}
            onClick={() => {}}
            title={props.book.title}
          >
            {
              match(getLibrariesFetcher.data)
                .with(P.nullish, () => {
                  return (
                    <div className='bg-gray-10 w-full h-full rounded-md'/>
                  );
                })
                .otherwise((value) => {
                  return (
                    <>
                      <UserLibraryInput
                        libraries={value.libraries.map(l => {
                          const shelfName = l.library.title.replace('{name}', props.user?.name ?? props.user!.email.split('@')[0]);
                          return {
                            label: shelfName,
                            value: l.libraryId.toString(),
                          };
                        })}
                        onChange={(value) => {
                          setSelectedLibrary(value);
                        }}
                        onClick={() => {
                          void track('click_user_library_on_upload_book', {
                            userId: props.user?.id,
                          });
                        }}
                        selectedValue={selectedLibrary ?? value.libraries[0].libraryId.toString()}
                      />
                      <input name='selectedLibrary' type='hidden' value={selectedLibrary || value.libraries[0].libraryId} />
                    </>
                  );
                })
            }
          </SearchBook>
          <article className='grid grid-rows-[auto_1fr] gap-2.5'>
            <h2 className='text-gray-90 text-label-1-bold xs:font-sfProBold sm:font-pretendardBold'>코멘트</h2>
            <Textarea 
              autoFocus
              className='border-none p-0 overflow-y-auto h-[25rem] resize-none placeholder:text-body-1-paragraph-medium placeholder:font-pretendardMedium'
              onChange={(e) => setValue(e.currentTarget.value)}
              placeholder='덧붙이고 싶은 이야기나 관점이 있나요? 나중에 알아보기 쉽도록 정리나 메모를 하는 것도 좋아요.'
              value={value}
              {...getTextareaProps(fields[GlobalAction.CreateComment])}
            />
          </article>
        </ModalMain>
      </fetcher.Form>
    </ModalContent>
  );
};