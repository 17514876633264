import type { VariantProps} from 'class-variance-authority';

import { cn } from '#app/utils/misc';
import { cva } from 'class-variance-authority';
import React from 'react';


const fBCva = cva(
  'grid items-center relative w-fit rounded-full cursor-pointer grid grid-flow-col justify-center',
  {
    defaultVariants: {
      size   : 'small',
      variant: 'white',
    },
    variants: {
      size: {
        small: 'justify-center text-caption-1-bold xs:font-sfProBold sm:font-pretendardBold',
      },
      variant: {
        black  : 'bg-gray-100 text-white',
        primary: 'bg-point-blue-70 text-white',
        white  : 'bg-white text-gray-90',
      }
    }
  }
);

interface FloatingButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>,
 VariantProps<typeof fBCva> {
  loading?: 'false' | 'true';
  preFixNode?: React.ReactNode;
}

export const FloatingButton = React.forwardRef<HTMLButtonElement, FloatingButtonProps>(({
  className, preFixNode, size, type, variant, ...props
}, ref) => {
  return (
    <button
      className={cn(
        fBCva({ size, variant }),
        'shadow-[0_-4px_36px_0px_rgba(20,22,24,0.12)]'
      )}
      ref={ref}
      type={type ?? 'submit'}
      {...props}
    >
      <div className={cn(
        'grid grid-flow-col rounded-full gap-1', 'grid items-center', 'active:bg-alpha-1.5 p-3',
        'py-[1.125rem] px-[1.625rem]',
      )}>
        {preFixNode}
        {props.children}
      </div>
    </button>
  );
});

FloatingButton.displayName = 'FloatingButton';