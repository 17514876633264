import React from 'react';

import { MenuItem } from './forms';
import { Icon } from './ui/icon';
import { Input } from './ui/input';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover/popover';


interface Props {
  libraries: {label:string, value:string}[];
  onChange: (value: string) => void;
  onClick: () => void;
  selectedValue: string;
}

const UserLibraryInput: React.FC<Props> = ({ libraries, onChange, onClick, selectedValue }) => {
  const [open, setOpen] = React.useState(false);
  const [selectLibraryValue, setSelectLibraryValue] = React.useState(selectedValue);
  const selectItem = libraries.find((value) => value.value === selectLibraryValue);
  return (
    <Popover open={open}>
      <PopoverTrigger asChild>
        <div className='relative w-fit h-fit max-w-60 hover:cursor-pointer' onClick={() => {
          onClick();
          setOpen(!open);
        }}>
          <Input
            className='text-label-2-medium font-pretendardMedium pr-8 pl-3 py-[10.5px] pointer-events-none'
            type='text'
            value={selectItem!.label}
          />
          <span className='absolute right-0 bottom-0 mr-4 mb-[9px]'>
            {
              open 
                ? <Icon className='animate-rotate-180' name='thin_expand_more' size={'ssmall'} /> 
                : <Icon className='animate-rotate-0' name='thin_expand_more' size={'ssmall'} />
            }
          </span>
        </div>
      </PopoverTrigger>
      <PopoverContent align='start' className='mt-1 w-[18rem] overflow-auto shadow-[0px_2px_8px_2px_rgba(0,0,0,0.16)]'>
        {
          libraries.map((value) => (
            <MenuItem
              className='focus:border-none active:border-none'
              disabled={false}
              index={0}
              key={'0'}
              label={value.label}
              onSelect={(selectedValue, selected) => {
                setSelectLibraryValue(selectedValue);
                onChange(selectedValue);
                setOpen(false);
              }}
              selectedValue={selectLibraryValue === value.value}
              value={value.value}
            />
          ))
        }
      </PopoverContent>
    </Popover>
  );
};

export default UserLibraryInput;
